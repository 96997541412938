<template>
  <section class="intro" id="intro">
    <div class="hero-content-wrapper">
      <div class="hero-content">
        <h1
          class="hero-header"
          :class="{ 'h-xlarge': viewWidth > sm, 'h-small': viewWidth < sm }"
        >
          Sveriges absolut enklaste fakturerings-& bokföringsprogram.
        </h1>
        <p
          :class="{ large: viewWidth > sm, small: viewWidth <= sm }"
          class="text"
        >
          Allt vi gör på acconomy handlar om att göra bokföring och ekonomi så
          enkelt som möjligt för Sveriges alla småföretagare. Nu kan du testa
          acconomy gratis i tre månader därefter 250 kr/månad (faktureras årsvis).
          
          
        </p>
        <div class="hero-buttons">
          <b-button
            class="hover-dark active-dark btn-intro"
            variant="purple"
            href="https://app.acconomy.com/create-account"
            target="_blank"
            @click="handleGetReadyClick()"
            >Bli kund!</b-button
          >
          <b-button
            variant="outline-primary btn-outline-almost-black"
            @click="handleProductPageClick()"
            >Vad ingår?</b-button
          >
        </div>
      </div>
      <div class="image-wrapper">
        <img
          class="svgImage"
          src="@/assets/img/heroIndex2.png"
          alt="Illustration av ekonomiprogrammet"
          width="400"
          height="250"
        />
      </div>
    </div>
  </section>
</template>

<script>
import breakpoints from "@/constants/breakpoints";


export default {
  name: "Intro",
  computed: {
    sm: () => breakpoints.sm,
    md: () => breakpoints.md,
    lg: () => breakpoints.lg,

    viewWidth() {
      return this.$store.state.base.viewWidth;
    }
  },

  methods: {
    handleGetReadyClick() {
      this.$gtm.trackEvent({
        event: "click_hero_cta_button",
        clickText: "Kom igång!",
        pageType: "Startsida"
      });
      // this.$router.push("/skapa-konto");
    },

    handleProductPageClick() {
      this.$gtm.trackEvent({
        event: "click_hero_cta_button",
        clickText: "Kom igång!",
        pageType: "Startsida"
      });
      this.$router.push("/ekonomiprogram");
    }
  }
};
</script>
